<template>
  <v-dialog
    v-model="dialog"
    width="750"
  >
    <v-card :disabled="load">
      <v-card-title class="pb-2">
        {{ incidente.estado == 2 ? 'Rea' : 'A' }}signar incidente
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pt-4 pb-0">
        <v-form ref="form">
          <v-row>
            <v-col cols="12" sm="8" class="d-flex py-0">
              <v-autocomplete
                v-model="tipo"
                label="Tipo incidente"
                item-text="nombre"
                item-value="id"
                :items="inc_tipos"
                outlined
                dense
              ></v-autocomplete>
              <v-btn
                color="success"
                class="ml-2"
                title="Nuevo tipo"
                small
                icon
                @click="dialog_tipos = true"
              >
                <v-icon>fas fa-plus</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12" sm="4" class="py-0">
              <v-autocomplete
                v-model="asignado"
                label="Personal"
                item-text="nombre"
                item-value="usuario_id"
                :items="personal_mesa.filter(p => p.usuario_id != incidente.usuario_asignado_id)"
                outlined
                dense
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4 mx-2">
        <v-btn
          color="error"
          class="mr-3"
          :disabled="load"
          @click="dialog = false"
        >
          Cancelar
        </v-btn>
        <BtnConfirmar
          icono="fas fa-user-plus"
          :nombre="`${incidente.estado == 2 ? 'Rea' : 'A'}signar`"
          :texto="`¿Desea ${incidente.estado == 2 ? 're' : ''}asignar el incidente Nº${incidente.id} a <strong>${personal_mesa.find(p => p.usuario_id == asignado) ? personal_mesa.find(p => p.usuario_id == asignado).nombre : ''}</strong>?
          ${incidente.estado == 2 ? `(Actualmente lo tiene ${personal_mesa.find(p => p.usuario_id == incidente.usuario_asignado_id) ? personal_mesa.find(p => p.usuario_id == incidente.usuario_asignado_id).nombre : ''})` : ''}`"
          :disabled="asignado == null || tipo == null"
          :loading="load"
          @action="asignar()"
        />
      </v-card-actions>
    </v-card>
    <!-- componentes -->
    <TiposIncidentes
      v-model="dialog_tipos"
      @seleccion="set_tipo"
    />
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import BtnConfirmar from '../util/BtnConfirmar'
import TiposIncidentes from './TiposIncidentes'

export default {
  data () {
    return {
      load: false,
      dialog_tipos: false,
      asignado: null,
      tipo: null
    }
  },
  props: {
    value: Boolean,
    incidente: Object
  },
  computed: {
    ...mapState('incidentes', ['inc_tipos', 'personal_mesa']),
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  components: {
    BtnConfirmar,
    TiposIncidentes
  },
  watch: {
    async dialog (val) {
      if (val) {
        this.tipo = JSON.parse(JSON.stringify(this.incidente.tipo))
      }
      else {
        val || this.limpiar()
      }
    }
  },
  methods: {
    async asignar () {
      if (!this.incidente.usuario_solicitante) {
        return this.$store.dispatch('show_snackbar', {
          text: 'Debe seleccionar un solicitante',
          color: 'orange'
        })
      }
      if (!this.incidente.descripcion.trim() || !this.incidente.asunto) {
        return this.$store.dispatch('show_snackbar', {
          text: 'El asunto y/o la descripción no pueden ser nulos',
          color: 'orange'
        })
      }
      if (!this.incidente.teamviewer || !this.incidente.empresa || !this.incidente.sucursal) {
        return this.$store.dispatch('show_snackbar', {
          text: 'Complete la empresa, sucursal y/o teamviewer para continuar',
          color: 'orange'
        })
      }
      this.load = true
      await this.$store.dispatch('incidentes/asignar', {
        id: this.incidente.id,
        us_asignado: this.asignado,
        tipo: this.tipo,
        empresa: this.incidente.empresa,
        sucursal: this.incidente.sucursal,
        local: this.incidente.local,
        teamviewer: this.incidente.teamviewer,
        asunto: this.incidente.asunto,
        descripcion: this.incidente.descripcion,
        solicitante: this.incidente.usuario_solicitante
      })
        .then((res) => {
          this.dialog = false
          this.$emit('actualizar', this.asignado, this.tipo)
          this.$store.dispatch('show_snackbar', {
            text: res.message,
            color: 'success'
          })
          // envio la notificacion push
          this.$store.dispatch('enviar_push')
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.load = false
    },
    set_tipo (tipo) {
      this.tipo = tipo
    },
    limpiar () {
      this.$refs.form.resetValidation()
      this.asignado = null
      this.tipo = null
    }
  }
}
</script>
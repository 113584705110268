<template>
  <v-row class="ma-0">
    <v-col>
      <div class="mt-n3 pb-1 px-1 body-1 font-weight-medium">
        <span
          style="cursor: pointer"
          @click="$refs.input.click()"
        >
          <v-icon small lefy>fas fa-paperclip</v-icon>
          <span class="ml-1 zelda">Adjuntar archivos</span>
        </span>
        <span v-if="archivos.length > 0 && multiple" class="body-2 ml-1">
          ( {{ archivos.length }} archivo{{ archivos.length == 1 ? '' : 's' }} seleccionado{{ archivos.length == 1 ? '' : 's' }} )
        </span>
      </div>
      <v-card>
        <!-- LISTA DE ARCHIVOS CARGADOS -->
        <v-card-text>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    Nombre
                  </th>
                  <th class="text-right">
                    Acciones
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in archivos"
                  :key="index"
                >
                  <td>
                    <v-icon
                      :color="get_tipo_archivo(item.type, 'color')"
                      small
                      left
                    >
                      {{ get_tipo_archivo(item.type, 'icono') }}
                    </v-icon>
                    {{ item.name }}
                  </td>
                  <td class="text-right">
                    <v-btn
                      v-show="get_tipo_archivo(item.type, 'tipo') === 'PDF' || get_tipo_archivo(item.type, 'tipo') === 'IMG'"
                      color="info"
                      title="Previsualizar"
                      small
                      icon
                      @click="previsualizar(item, get_tipo_archivo(item.type, 'tipo'))"
                    >
                      <v-icon small>fas fa-eye</v-icon>
                    </v-btn>
                    <v-btn
                      color="error"
                      title="Quitar"
                      small
                      icon
                      @click="quitar(item)"
                    >
                      <v-icon small>fas fa-trash</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <!-- DROPZONE -->
        <v-card
          :disabled="!multiple ? (archivos.length >= 1 ? true : false) : false"
          class="mt-n2"
          flat
        >
          <v-card-text class="py-3">
            <div
              class="d-flex body-2 pb-2 mx-4"
              style="cursor: default"
            >
              <div>
                Tipo{{ tipos.length > 1 ? 's' : '' }} de archivo{{ multiple ? 's' : '' }} válido{{ multiple ? 's' : '' }}: {{ tipos.join(', ') }}
              </div>
              <v-spacer></v-spacer>
              <div
                class="text-right"
                title="Por archivo"
              >
                Peso máximo: {{ max_size }}MB
              </div>
            </div>
            <v-row
              class="d-flex justify-center mx-4"
              style="border-style: dashed; border-width: 2px; border-radius: 15px; cursor: pointer;"
              :style="$vuetify.theme.dark ? 'background-color: #0f0f0f;' : 'background-color: #f0f0f0;'"
              no-gutters
              @dragover.prevent="hover = true"
              @dragleave.prevent="hover = false"
              @drop.prevent="drop"
              @click="$refs.input.click()"
            >
              <input
                ref="input"
                type="file"
                class="d-none"
                :multiple="multiple"
                :accept="tipos.join(',')"
                @change="buscar($event)"
              >
              <v-col cols="12" class="d-flex justify-center mt-6">
                <v-icon
                  :large="hover ? false : true"
                  :x-large="hover ? true : false"
                >
                  {{ hover ? 'fas fa-folder-open' : 'fas fa-folder' }}
                </v-icon>
              </v-col>
              <v-col cols="12" class="d-flex justify-center mt-2 mb-4">
                <h1 class="body-1 font-weight-bold mx-4 text-center">
                  Arrastre {{ multiple ? 'los' : 'el' }} archivo{{ multiple ? 's' : '' }} o haga click aquí
                </h1>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-card>
    </v-col>
    <!-- COMPONENTES -->
    <PDFViewer
      v-model="dialog_pdf"
      :pdf="pdf"
      :nombre="nombre_ver"
      :titulo="nombre_ver"
    />
    <ImgViewer
      v-model="dialog_img"
      :img="img"
      :nombre="nombre_ver"
    />
  </v-row>
</template>

<script>
/**
 *  Componente genérico para cargar archivos, devuelve un array de files
 */

import { tipos_archivos, getBase64 } from '../../util/utils'
import PDFViewer from '../../util/plantillas/PDFViewer'
import ImgViewer from './ImgViewer'

export default {
  data() {
    return {
      hover: false,
      dialog_pdf: false,
      dialog_img: false,
      nombre_ver: '',
      pdf: '',
      img: ''
    }
  },
  props: {
    value: Array,
    tipos: Array,      // un array de string con las extensiones permitidas, ej: ['.png', '.webp']
    multiple: Boolean, // permite subir multiples archivos
    max_size: Number   // tamaño maximo del archivo en MB
  },
  computed: {
    archivos: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  components: {
    PDFViewer,
    ImgViewer
  },
  methods: {
    drop (event) {
      this.hover = false
      const files = event.dataTransfer.files
      if (!this.multiple && files.length > 1) {
        this.$refs.input.value = ''
        return this.$store.dispatch('show_snackbar', {
          text: 'Solo puede subir un archivo',
          color: 'error'
        })
      }
      this.validar(files)
    },
    buscar (event) {
      const files = event.target.files
      if (files) this.validar(files)
    },
    async validar (files) {
      let errores = []

      for (let index = 0; index < files.length; index++) {

        const file = files[index]
        const tipo = file.type

        // busca en el array de tipos_archivos el tipo de archivo del archivo que subio
        const tipo_archivo = tipos_archivos.find(a => a.mime === tipo)

        if (tipo_archivo) {
          // si encuentra el tipo de archivo pasa a validar que pertenezca a por lo menos un tipo de extension existente en el prop de tipos
          if (this.tipos.find(a => a === tipo_archivo.extension)) {
            // la extension del tipo del archivo existe en los tipos validos
            
            // valida el pesp del archivo (en MB)
            if (file.size <= this.max_size * 1024 * 1024) {
              
              // verifica que no exista un archivo con el mismo nombre en la lista
              if (!this.archivos.find(a => a.name.toLowerCase() === file.name.toLowerCase())) {
                // guarda el archivo en el array de archivos
                this.archivos.push(file)

              } else {
                // archivo duplicado
                errores.push(`<div>Ya hay un archivo con el mismo nombre: ${file.name}</div>`)
              }

            } else {
              // excedio el peso
              errores.push(`<div>El archivo ${file.name} no debe pesar más de ${this.max_size}MB</div>`)
            }

          } else {
            // subio un archivo con una extension no valida
            errores.push(`<div>${file.name}: tipo de archivo no válido</div>`)
          }

        } else {
          // si no lo encuentra es porque subio un tipo de archivo que no esta registrado en el array tipos_archivos
          errores.push(`<div>${file.name}: tipo de archivo no válido para el sistema</div>`)
        }
        
      }

      // limpia el input de archivos
      this.$refs.input.value = ''

      if (errores.length > 0) {
        this.$store.dispatch('show_snackbar', {
          text: errores.join(''),
          color: 'error'
        })
      }
    },
    async previsualizar (file, tipo) {

      this.nombre_ver = file.name

      await getBase64(file).then(data => {
        if (tipo == 'PDF') {
          this.pdf = data
          this.dialog_pdf = true
        }
        else if (tipo == 'IMG') {
          this.img = data
          this.dialog_img = true
        }
      })

    },
    quitar (file) {
      const index = this.archivos.indexOf(file)
      this.archivos.splice(index, 1)
      this.$refs.input.value = ''
    },
    // obtiene un atributo especifico del array tipos_archivos en base al mime del archivo indicado
    get_tipo_archivo (tipo, atributo) {
      if (!tipo) return ''
      return tipos_archivos.find(a => a.mime === tipo)[atributo]
    }
  }
}
</script>

<style>
.zelda:hover {
  text-decoration: underline;
}
</style>
<template>
  <v-dialog
    v-model="dialog"
    width="700"
    scrollable
  >
    <v-card>
      <v-card-text class="pt-3 px-3 pb-0">
        <FileDropzone2
          v-model="files"
          :tipos="['.txt', '.png', '.jpg', '.webp', '.doc', '.docx', '.csv', '.xls', '.xlsx', '.pdf']"
          :multiple="true"
          :max_size="25"
        />
      </v-card-text>
      <v-card-actions class="d-flex justify-end pt-0 pb-4 px-6">
        <v-btn
          color="secondary"
          @click="dialog = false"
        >
          Volver  
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import FileDropzone2 from '../util/FileDropzone2'

export default {
  props: {
    value: Boolean,
    archivos: Array
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    files: {
      get () {
        return this.archivos
      },
      set (value) {
        this.$emit('update:archivos', value)
      }
    }
  },
  components: {
    FileDropzone2
  }
}
</script>